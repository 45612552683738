.headBlock {
    width: 100%;
    padding: 0 30px 20px 0;
    display: flex;
    justify-content: space-between;

    @media (min-width: 768px) {
        padding: 0 70px 30px 0;
    }
}

%headText {
    margin-left: 40px;
    font-size: 20px;
    font-weight: 700;

    @media (min-width: 768px) {
        margin-left: 100px;
        font-size: 40px;
    }
}

.leftHeadBlock {
    display: flex;
    flex-direction: column;

    h1 {
        @extend %headText;
        margin-top: 15px;
        text-transform: uppercase;

        @media (min-width: 768px) {
            margin-top: 40px;
        }
    }
}

%abbreviation {
    font-size: 170px;
    font-weight: 900;
    line-height: 85%;

    @media (min-width: 768px) {
        font-size: 370px;
    }
}

.abbreviation_one {
    @extend %abbreviation;
    margin-left: 27px;

    @media (min-width: 768px) {
        margin-left: 68px;
    }
}

.abbreviation_many {
    @extend %abbreviation;
}

.translate_name {
    @extend %headText;
    font-weight: 500;
}

.atomic_mass {
    @extend %headText;
}

.rightHeadBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    svg {
        width: 80px;
        height: 80px;
        flex-shrink: 0;

        g {
            g {
                path {
                    fill: transparent;
                    animation: draw 7s linear;
                }

                @keyframes draw {
                    0% {
                        stroke-dasharray: 500;
                        stroke-dashoffset: 500;
                    }

                    100% {
                        stroke-dashoffset: 0;
                    }
                }
            }
        }
    }

    p {
        color: #fff;
        font-size: 40px;
        font-weight: 700;
    }

    @media (min-width: 768px) {
        svg {
            width: 165px;
            height: 165px;
        }

        p {
            font-size: 80px;
        }
    }
}

.infoBlock {
    width: 100%;
    padding: 30px;

    display: flex;
    flex-direction: column;
    gap: 15px;

    a {
        color: blue;
        text-decoration: underline !important;

        svg {
            width: 18px;
            height: 18px;
            margin-bottom: -3px;
        }
    }

    .textBase {
        margin: 0px 0 0 10px;
        font-size: 18px;
        white-space: pre-line;
    }

    @media (min-width: 768px) {
        padding: 40px 70px 70px 75px;
        gap: 25px;

        a {
            svg {
                width: 22px;
                height: 22px;
                margin-bottom: -5px;
            }
        }

        .textBase {
            margin: 0px 0 0 25px;
            font-size: 22px;
        }
    }
}

.language_switch {
    position: absolute;
    top: 55px;
    right: 20px;
    padding: 12px;
    font-size: 18px;
    white-space: pre-line;

    @media (min-width: 360px) {
        top: 8px;
    }

    @media (min-width: 390px) {
        top: 13px;
    }

    @media (min-width: 430px) {
        top: 18px;
    }

    @media (min-width: 768px) {
        top: 25px;
        right: 80px;
        font-size: 22px;
    }

    @media (min-width: 1025px) {
        top: 45px;
    }
}

// h3 {
//     font-size: 18px;
//     font-weight: 700;

//     @media (min-width: 768px) {
//         font-size: 22px;
//     }
// }