.header {
    width: 100%;
    // height: 60px;
    height: 100px;
    padding: 5px 0 5px 40px;

    display: flex;

    @media (min-width: 360px) {
        height: 60px;
    }

    @media (min-width: 390px) {
        height: 70px;
    }

    @media (min-width: 430px) {
        height: 80px;
    }

    @media (min-width: 768px) {
        height: 100px;
        padding: 5px 0 5px 100px;
    }

    @media (min-width: 1025px) {
        height: 140px;
        padding: 10px 0 10px 100px;
        // padding: 10px 0 0 40px;
        // justify-content: center;
    }

    div {
        position: relative;
        // align-items: center;

        // @media (min-width: 1025px) {
        //     &::after {
        //         content: '';
        //         position: absolute;
        //         top: 100%;
        //         left: -42.5px;

        //         width: 0px;
        //         height: 0px;
        //         border-top: 20px solid #fff;
        //         border-left: 70px solid transparent;
        //         border-right: 70px solid transparent;
        //     }
        // }

        img {
            position: relative;
            z-index: 1;
            // width: 50px;
            // height: 50px;
            height: 50px;
            max-width: 100%;
            margin-bottom: -4px;

            @media (min-width: 390px) {
                height: 60px;
            }

            @media (min-width: 430px) {
                height: 70px;
            }

            @media (min-width: 768px) {
                height: 90px;
            }
        

            @media (min-width: 1025px) {
                // width: 55px;
                // height: 55px;
                height: 120px;
            }
        }
    }
}